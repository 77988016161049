import React from 'react'
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";
import {FaFacebookF, FaLinkedin, FaTwitter} from "react-icons/fa";

const Footer = () => (
	<footer className="bg-light p-3 p-md-5">
		<Container>
			<Row>
				<Col xs="12" className="mb-4 mb-md-0 text-center">
					<div>© <Link to="/" className="link-secondary">Euroweb B.V.</Link> All Rights Reserved</div>
					<Row className="justify-content-center">
						<Col xs="12" md="8"><p className="text-center"><small>Euroweb B.V. is a consulting and software engineering firm consisting of a group of talented individuals. We help entrepreneurs, and companies with product development and tech side of the business.</small></p></Col>
					</Row>
					<Row className="justify-content-center">
						<Col xs="12" md="8">
							<p className="text-center">
								<small className="font-italic">
									Euroweb B.V. KvK: 81944756 Address: Langendijkstraat 9B, 2013EL, Haarlem, The Netherlands
								</small>
							</p>
						</Col>
					</Row>
					<div className="h4">
						<a href="https://www.linkedin.com/company/euroweb-bv" className="mr-2" aria-label="LinkedIn"><FaLinkedin /></a>
						{/*<a href="https://facebook.com/nextwebsoft" className="mr-2" aria-label="Facebook"><FaFacebookF /></a>*/}
						{/*<a href="https://twitter.com/NextwebSoftware" aria-label="Twitter"><FaTwitter /></a>*/}
					</div>
				</Col>
			</Row>
		</Container>
	</footer>
);

export default Footer;
